export const btn = {
  btnTarget(e) {
    const $target = $(e.target);
    if ($target.prop("tagName") == "A" || $target.prop("tagName") == "BUTTON") {
      return $target;
    }
    let $btn = $target.find("button[type='submit']");
    if ($btn) {
      return $btn;
    }
    return $target.find("button");
  },
  sending(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == "A") {
      $btn.addClass("disabled");
    }
    $btn.addClass("btn-target");
    const originalText = $btn.text();
    $btn.css("height", `${$btn.innerHeight()}px`);
    $btn.css("width", `${$btn.innerWidth()}px`);

    $btn.addClass("btn-sending");
    $btn.attr("original-text", originalText);
    $btn.prop("disabled", true);
    // なぜか遅らせないと反映されない
    setTimeout(() => {
      $btn.text("");
    }, 10);
  },
  reset(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == "A") {
      $btn.removeClass("disabled");
    } else {
      $btn.prop("disabled", false);
    }
    $btn.removeClass("btn-sending");
    $btn.removeClass("btn-success");
    $btn.removeClass("btn-warning");
    $btn.removeClass("btn-sending-icon-only");
    $btn.css("height", "");
    $btn.css("width", "");
    const originalText = $btn.attr("original-text");
    $btn.html("保存しました");
    $btn.html(originalText);
  },
  error(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == "A") {
      $btn.removeClass("disabled");
    }
    $btn.removeClass("btn-sending");
    $btn.addClass("btn-warning");
    $btn.html("失敗しました");
  },
  success(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == "A") {
      $btn.removeClass("disabled");
    }
    $btn.removeClass("btn-sending");
    $btn.addClass("btn-success");
    $btn.html("保存しました");
  },
};
